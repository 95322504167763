import React, { useContext, useReducer, lazy } from 'react'
import ReactDOM from 'react-dom/client';
import { useLocation } from 'react-use'
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

import Context from './context'
import Reducer from './reducer'

import { initGoogleAnalytics } from './utiltiies/analytics'
import MainLayout from './layouts/Main'
import { ThemeProvider, createTheme } from '@mui/material';

const HomePage = lazy(() => import('./pages/Home'))
const AboutPage = lazy(() => import('./pages/About'))
const ContactPage = lazy(() => import('./pages/Contact'))

const theme = createTheme({
    // components: {
    //     MuiGrid2: {
    //         styleOverrides: {
    //             root: {
    //                 width: '100%'
    //             }
    //         }
    //     }
    // }
});

const Root = () => {

    const location = useLocation()
    const initialState = useContext(Context)
    const [state, dispatch] = useReducer(Reducer, initialState)

    initGoogleAnalytics(location.host);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Context.Provider value={{ state, dispatch }} >
                    <Routes>
                        <Route element={<MainLayout />} >
                            <Route exact path='/' element={<HomePage />}/>
                            <Route exact path='/about' element={<AboutPage />} />
                            <Route exact path='/contact' element={<ContactPage />} />
                            <Route path='/*' element={<HomePage/>} />
                        </Route>
                    </Routes>
                </Context.Provider>
            </Router>
        </ThemeProvider>
    )
}

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
