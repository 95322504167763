import React from 'react'
import { Grid2 as Grid, CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'


const Loader = ({ classes }) => {
    return (
        <Grid container direction='column' className={classes.loader}
            justifyContent='center' alignItems='center'
        >
            <CircularProgress />
        </Grid>
    )
}

const styles = {
    loader: {
        height: '100vh',
    },
}

export default withStyles(styles)(Loader)
